/** @format */

import {
  computedFrom
} from 'aurelia-framework';
import userService from 'services/api/userService';
import apiService from 'services/api/apiService';

import authService from './authService';
import storageService, {
  STORAGE_KEYS
} from 'services/storageService';
import eventService, {
  EventsList
} from 'services/eventService';

//hmm, better way to do this?
export const DEFAULT_THEME = {
  backgroundColor: '#465A64',
  textColor: '#ffffff'
};

/**
 * The UI service, to keep global ui state - keep it slim!
 * ex: list filters, toggles icons/menus, etc etc
 * TODO: Persist this in the localstorage
 */
export class UIService {
  /**
   * Construtor for the service - load initial state
   */
  constructor() {
    this.userService = userService;

    //
    this.api = apiService;
    this.storageService = storageService;

    this.userId = null;
    this.state = {};
    this.viewData = {};

    //no need to unsub, uiService is live at all times
    eventService.subscribe(
      EventsList.AppThemePreviewChanged,
      this.onAppThemePreviewChanged
    );

    eventService.subscribe(EventsList.UserDetailsChanged, this.loadState);

    this.loadState();
  }

  @computedFrom('state.isSlideNavOpen')
  get isSlideNavOpen() {
    return this.state.isSlideNavOpen;
  }

  set isSlideNavOpen(value) {
    let isOpen = !!value;
    this.state.isSlideNavOpen = isOpen;
    return isOpen;
  }

  @computedFrom('state.whatsNew')
  get whatsNew() {
    return this.state.whatsNew;
  }

  set whatsNew(value) {
    this.updateWhatsNew(true);
    return value;
  }

  @computedFrom('state')
  get userSettings() {
    return this.state.userSettings;
  }

  @computedFrom('state.user.name')
  get initialLetter() {
    if (this.state.user) {
      return this.state.user.name.substring(0, 1);
    }
    return '?'
  }

  @computedFrom('state.userSettings')
  get mapProvider() {
    let userMapProvider = this.state.userSettings ?
      this.state.userSettings.mapProvider + '' :
      '';

    return ({
      GOOGLE: 'google',
      OSM: 'osm'
    } [userMapProvider.toUpperCase()] || 'osm');
  }

  @computedFrom('state')
  get impersonation() {
    return this.state.impersonation;
  }

  @computedFrom('state.userSettings')
  get showGroups() {
    return this.state.userSettings && this.state.userSettings.showGroups;
  }

  @computedFrom('state.userSettings')
  get assetAddressOnLists() {
    return (
      this.state.userSettings && this.state.userSettings.assetAddressOnLists
    );
  }

  @computedFrom('state.userSettings.assetNameFormatting')
  get assetNameFormat() {
    return (
      this.state.userSettings && this.state.userSettings.assetNameFormatting
    );
  }

  @computedFrom(
    'state.userSettings.mapClustering',
    'state.userSettings.mapClusterLimit'
  )
  get clustering() {
    if (this.state.userSettings) {
      let cluster = this.state.userSettings.mapClustering;
      let limit = cluster ? 0 : this.state.userSettings.mapClusterLimit || 250;

      return limit;
    }
    return false;
  }

  @computedFrom('state.userSettings.mapAssetLabels')
  get assetLabels() {
    if (this.state.userSettings) {
      return this.state.userSettings.mapAssetLabels;
    }
  }

  @computedFrom('state.userSettings.experimentalFeatures')
  get experimentalFeatures() {
    if (this.state.userSettings) {
      return this.state.userSettings.experimentalFeatures;
    }
  }

  @computedFrom('state.userSettings.ex_thirdparty_dlc')
  get ex_hasDLC() {
    return (this.state.userSettings && this.state.userSettings.ex_thirdparty_dlc &&
      this.state.userSettings.ex_thirdparty_dlc.isEnabled) ? true : false;
  }

  //note: this is ugly, refactor this
  @computedFrom('state.userSettings.theme', 'state.previewTheme')
  get theme() {
    if (this.state.userSettings) {
      //
      const theme = {
        ...DEFAULT_THEME,
        ...this.state.userSettings.theme,
        ...this.state.previewTheme
      };

      return theme;
    }
  }

  /**
   * Load the initial state of the UI
   */
  loadState = () => {
    this.userService.getSelf().then(user => {
      this.userId = user.id;
      this.state.user = {
        name: user.name,
        accountName: user.accountName
      }

      // We merge all user data sources in a single object (like user.accountSettings and user.prefences )
      // as this object grow independently it makes sense used them seperately
      // All acess is controlled trhough uiService getters, so should be easy to refactor
      this.state.userSettings = Object.assign({},
        user.accountSettings,
        user.preferences
      );
      this.updateWhatsNew();
    });

    this.state = {
      isSlideNavOpen: false,
      impersonation: this.storageService.get(STORAGE_KEYS.IMPERSONATE_KEY_NAME),
      whatsNew: false
    };
  };

  onAppThemePreviewChanged = theme => {
    this.state.previewTheme = theme;
  };

  /* global __RELEASEVERSION__ */
  updateWhatsNew(value) {
    const {
      APPVERSION_KEY_NAME,
      WHATSNEW_KEY_PREFIX
    } = STORAGE_KEYS;

    let lastVersion = this.storageService.get(APPVERSION_KEY_NAME);
    let currentVersion = __RELEASEVERSION__; //this is created by webpack (check config)
    let versionChanged = lastVersion !== currentVersion;

    let current_user_key = `${WHATSNEW_KEY_PREFIX}-${currentVersion}-${
      this.userId
    }`;

    if (value === true) {
      this.storageService.set(current_user_key, true);
    }

    if (versionChanged) {
      this.storageService.set(APPVERSION_KEY_NAME, currentVersion);

      if (lastVersion) {
        let last_user_key = `${WHATSNEW_KEY_PREFIX}-${lastVersion}-${
          this.userId
        }`;
        this.storageService.remove(last_user_key);
      }
    }

    this.state.whatsNew =
      versionChanged || !this.storageService.get(current_user_key);
  }

  getCountries() {
    return this.api.get('api/areas/countries').then(list => {
      return list;
    });
  }

  getServerUnits(type) {
    return this.api.get('api/units', {
      type
    });
  }

  impersonate(username, noRedirect) {
    //revert impersonation
    const {
      IMPERSONATE_KEY_NAME
    } = STORAGE_KEYS;

    if (!username && this.impersonation) {
      authService.setToken(this.impersonation.token);
      this.storageService.remove(IMPERSONATE_KEY_NAME);
      if (!noRedirect) {
        //  window.location = window.location.pathname;
        window.location.reload(false);
      }
      return Promise.resolve(true);
    }

    if (username) {
      //start impersonation
      return this.userService
        .getImpersonationTokens(username)
        .then(response => {
          if (response && response.parent) {
            authService.setToken(response.token);

            //server is not retuning original user token, but current user token, which could be an impersonation token.
            let originalToken = this.impersonation ?
              this.impersonation.token :
              response.parent.token;

            let originalIsAdmin = this.impersonation ?
              this.impersonation.isAdmin :
              this.userService.hasRole('admin');

            let impersonation = {
              username: username,
              token: originalToken,
              isAdmin: originalIsAdmin
            };

            this.storageService.set(IMPERSONATE_KEY_NAME, impersonation);
            //
            if (!noRedirect) {
              // window.location = window.location.pathname;
              window.location.reload(false);
            }
            //
            return Promise.resolve(true);
          }
          return Promise.reject(false);
        })
        .catch(() => {
          return Promise.reject(false);
        });
    }
  }
}

const uiService = new UIService();
export default uiService;
